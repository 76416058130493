<template>
  <div class="feedback-btn-wrapper">
    <v-btn
      v-bind="props.activatorProps"
      text="Give us Feedback"
      variant="outlined"
      color="secondary"
      class="btn-floating"
    >
      <template #prepend>
        <v-icon :icon="mdiCommentAccount" class="feedback-btn-icon" />
      </template>
    </v-btn>
  </div>
</template>

<script setup>
import { mdiCommentAccount } from '@mdi/js';

defineOptions({
  name: 'FloatingBtn',
});

const props = defineProps({
  activatorProps: { type: Object, required: true },
});
</script>

<style scoped>
.feedback-btn-wrapper {
  z-index: 99;
  position: fixed;
  bottom: 20%;
  right: 0%;
}

.btn-floating {
  letter-spacing: 2px;
  /* text-orientation: ; */
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: min-content;
  padding: 16px 0px;
  border-radius: 0px 15px 15px 0px;
  min-width: 40px !important;
  border-block-end: 0px;
}

.feedback-btn-icon {
  transform: rotate(90deg);
}
.v-btn--size-default {
  font-size: 12px;
}
</style>
